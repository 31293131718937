<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="1200px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-select
        v-model="depot_id"
        clearable
        :disabled="type == 2"
        style="width: 200px"
        placeholder="仓库"
      >
        <el-option
          v-for="(i, idx) in depotList"
          :key="idx"
          :value="i.id"
          :label="i.depot_name"
        ></el-option>
      </el-select>
      <el-table
        v-loading="loading"
        stripe
        style="margin-top: 10px"
        :data="list"
      >
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.label == '商品名称' ? '240' : ''"
          align="center"
        >
          <template #default="{ row, $index }">
            <div v-if="item.label == '品牌'">
              <!-- <brand-select
                ref="brandSelect"
                :place="'全部品牌'"
                style="width: 100%"
                :brand-id="Number(row.brandId)"
                :show-end="true"
                @brand-select-change="brandSelectId($event, row, tableIndex)"
              ></brand-select> -->
              {{ row.brandName }}
            </div>
            <div v-else-if="item.label == '商品名称'">
              <goods-search
                v-if="type != 2"
                :f-key="row.goodsName"
                @add-rows="addRows"
                @select-goods-all="selectGoods($event, row, $index)"
              ></goods-search>
              <div v-else>{{ row.goodsName }}</div>
            </div>
            <div v-else-if="item.label == '规格'">
              {{ row.goodsSpec }}
            </div>
            <div v-else-if="item.label == '单位'">
              <el-select
                v-model="row.unitID"
                placeholder="选择单位"
                style="width: 100%"
                :disabled="type == 2"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="item_ in row.arr_unit"
                  :key="item_.id"
                  :label="item_.unit_name"
                  :value="item_.id"
                ></el-option>
              </el-select>
            </div>
            <div v-else-if="item.label == '单价'">
              <!-- {{ row.goodsPrice }} -->
              <el-input
                v-model="row.goodsPrice"
                :disabled="type == 2"
              ></el-input>
            </div>
            <div v-else-if="item.label == '申请数量'">
              <el-input
                v-model="row.apply_read_num"
                placeholder="数量"
                type="number"
                :min="0"
                :disabled="type != 1"
              ></el-input>
            </div>
            <div v-else-if="item.label == '审核数量' && type != 1">
              <el-input
                v-model="row.read_num"
                placeholder="审核数量"
                type="number"
                :disabled="type != 3"
                :min="0"
              ></el-input>
            </div>
            <div v-else-if="item.label == '金额'">
              <span v-if="type != 2">
                {{
                  (
                    Number(row.goodsPrice) * Number(row.apply_read_num) || 0
                  ).toFixed(2) || 0
                }}
              </span>
              <span v-else>{{ row.money }}</span>
            </div>
            <div v-else-if="item.label == '生产日期'">
              <el-date-picker
                v-model="row.goodsDate"
                style="width: 100%"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                :disabled="type == 2"
              ></el-date-picker>
            </div>
            <div v-else-if="item.label == '备注'">
              <el-input
                v-model="row.remark"
                placeholder="备注"
                :disabled="type == 2"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="type != 2"
          prop=""
          align="center"
          label="操作"
          fixed="right"
          width="130px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleCopy($index, row)"
            >
              复制
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDelete($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-input
        v-model="remark"
        :disabled="type == 2"
        style="width: 200px; margin-top: 10px"
        placeholder="备注"
      ></el-input>
      <div style="margin-top: 10px; text-align: right">
        <el-button v-if="type == 1" type="primary" @click="handleCreate">
          添加
        </el-button>
        <el-button v-if="type == 3" type="primary" @click="handleCreate">
          审核
        </el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  // import BrandSelect from '@/baseComponents/brandSelect'
  import _ from 'lodash'
  export default {
    name: 'Create',
    components: { GoodsSearch },
    data() {
      return {
        depot_id: '',
        depotList: [],
        showDialog: false,
        title: '标题',
        // type1添加 2查看 3审核
        type: 1,
        id: null,
        url: {
          depotSelect: '/baseAdmin/common/depot-list',
          create: '/depotAdmin/bill-loss/add',
          detail: '/depotAdmin/bill-loss/info',
          review: '/depotAdmin/bill-loss/check',
        },
        list: [],
        remark: '',
        loading: false,
        checkList: [
          '品牌',
          '商品名称',
          '规格',
          '单位',
          '单价',
          // '报溢数量',
          '申请数量',
          '审核数量',
          '金额',
          '生产日期',
          '备注',
        ],
        // columns: [
        //   {
        //     order: 1,
        //     label: '品牌',
        //     prop: 'brandId',
        //     width: '130px',
        //   },
        //   {
        //     order: 2,
        //     label: '商品名称',
        //     prop: 'goodsId',
        //     width: '220',
        //   },
        //   {
        //     order: 3,
        //     label: '规格',
        //     prop: 'goodsSpec',
        //     width: '130',
        //   },
        //   {
        //     order: 4,
        //     label: '单位',
        //     prop: 'unitID',
        //     width: '130',
        //   },
        //   {
        //     order: 4.5,
        //     label: '单价',
        //     prop: 'goodsPrice',
        //     width: '130',
        //   },
        //   // {
        //   //   order: 5,
        //   //   label: '报溢数量',
        //   //   prop: 'goodsNum',
        //   //   width: '100',
        //   // },
        //   // {
        //   //   order: 5.3,
        //   //   label: '申请数量',
        //   //   align: 'center',
        //   //   prop: 'read_num',
        //   //   width: '100',
        //   // },
        //   {
        //     order: 5.4,
        //     label: '审核数量',
        //     align: 'center',
        //     prop: 'apply_read_num',
        //     width: '100',
        //   },
        //   {
        //     order: 5.5,
        //     label: '金额',
        //     prop: 'money',
        //     width: '100',
        //   },
        //   {
        //     order: 6,
        //     label: '生产日期',
        //     prop: 'goodsDate',
        //     width: '150',
        //   },
        //   {
        //     order: 7,
        //     label: '备注',
        //     prop: 'remark',
        //     width: '130',
        //   },
        // ],
      }
    },
    computed: {
      columns() {
        if (this.type == 1) {
          return [
            {
              order: 1,
              label: '品牌',
              prop: 'brandId',
              width: '130px',
            },
            {
              order: 2,
              label: '商品名称',
              prop: 'goodsId',
              width: '220',
            },
            {
              order: 3,
              label: '规格',
              prop: 'goodsSpec',
              width: '130',
            },
            {
              order: 4,
              label: '单位',
              prop: 'unitID',
              width: '130',
            },
            {
              order: 4.5,
              label: '单价',
              prop: 'goodsPrice',
              width: '130',
            },
            // {
            //   order: 5,
            //   label: '报溢数量',
            //   prop: 'goodsNum',
            //   width: '100',
            // },
            {
              order: 5.3,
              label: '申请数量',
              align: 'center',
              prop: 'read_num',
              width: '100',
            },
            {
              order: 5.5,
              label: '金额',
              prop: 'money',
              width: '100',
            },
            {
              order: 6,
              label: '生产日期',
              prop: 'goodsDate',
              width: '150',
            },
            {
              order: 7,
              label: '备注',
              prop: 'remark',
              width: '130',
            },
          ]
        } else {
          return [
            {
              order: 1,
              label: '品牌',
              prop: 'brandId',
              width: '130px',
            },
            {
              order: 2,
              label: '商品名称',
              prop: 'goodsId',
              width: '220',
            },
            {
              order: 3,
              label: '规格',
              prop: 'goodsSpec',
              width: '130',
            },
            {
              order: 4,
              label: '单位',
              prop: 'unitID',
              width: '130',
            },
            {
              order: 4.5,
              label: '单价',
              prop: 'goodsPrice',
              width: '130',
            },
            // {
            //   order: 5,
            //   label: '报溢数量',
            //   prop: 'goodsNum',
            //   width: '100',
            // },
            {
              order: 5.3,
              label: '申请数量',
              align: 'center',
              prop: 'read_num',
              width: '100',
            },
            {
              order: 5.4,
              label: '审核数量',
              align: 'center',
              prop: 'apply_read_num',
              width: '100',
            },
            {
              order: 5.5,
              label: '金额',
              prop: 'money',
              width: '100',
            },
            {
              order: 6,
              label: '生产日期',
              prop: 'goodsDate',
              width: '150',
            },
            {
              order: 7,
              label: '备注',
              prop: 'remark',
              width: '130',
            },
          ]
        }
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.depotSelect()
          this.init()
          if (this.type != 1) {
            this.fetchData()
          }
        } else {
          this.depot_id = null
          this.remark = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      init() {
        if (this.type != 2) {
          // 不是查看
          this.list = [this.obj()]
        }
      },
      // 报溢单 详情
      async fetchData() {
        this.loading = true
        let { data } = await postAction(this.url.detail, {
          loss_id: this.id,
        })
        let goods = data.goods
        goods.forEach((i) => {
          i.goodsId = i.goods_id
          i.goodsName = i.goods_name
          i.goodsNum = i.read_num
          i.goodsPrice = i.goods_price
          i.goodsSpec = i.goods_spec
          i.goodsUnit = i.goods_unit
          i.unitID = i.unit_id
          i.goodsDate = i.goods_date
          i.brandId = i.brand_id
          i.brandName = i.goods_brand
          i.arr_unit = i.arr_unit
          i.remark = i.remark
          i.money = i.total_price
        })
        this.depot_id = data.info.depot_id
        this.remark = data.info.remark
        this.list = goods
        if (this.type != 2) {
          this.list.push(this.obj())
        }
        this.loading = false
      },
      // 创建报溢单
      async handleCreate() {
        let goods = []
        this.list.forEach((i) => {
          if (i.goodsId > 0) {
            goods.push(i)
          }
        })
        let endData = {
          remark: this.remark,
          depot_id: this.depot_id,
          goods: goods.length == 0 ? '' : JSON.stringify(goods),
          loss_type: 2,
        }
        if (this.type == 1) {
          // 添加
          let { data } = await postAction(this.url.create, endData)
          this.$message.success('添加成功')
          this.$emit('refresh')
          this.close()
        } else if (this.type == 3) {
          // 审核
          endData.loss_id = this.id
          let { data } = await postAction(this.url.review, endData)
          this.$message.success('审核成功')
          this.$emit('refresh')
          this.close()
        }
      },
      // 表格每条数据
      obj() {
        return {
          goodsId: '',
          goodsName: '',
          goodsNum: 0,
          goodsPrice: '',
          goodsSpec: '',
          goodsUnit: '',
          money: '',
          unitID: '',
          goodsDate: '',
          brandId: null,
          brandName: '',
          arr_unit: [],
          remark: '',
        }
      },
      // 仓库下拉
      depotSelect() {
        postAction(this.url.depotSelect, { pageSize: -1 })
          .then((res) => {
            this.depotList = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      // 品牌选择
      brandSelectId(val, row, idx) {
        console.log('val', val)
        if (row) {
          console.log('', row, val)
          this.$set(this.list[idx], 'brandId', val)
          // this.list[idx].brandId = val
        }
      },
      close() {
        this.showDialog = false
      },
      addRows(val) {
        console.log('添加rows', val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit,
              goodsId: item.goods_id,
              goodsName: item.goods_name,
              goodsPrice: item.goods_price,
              goodsSpec: item.specs,
              goodsNum: 0,
              unitID: item.unit_id,
              unitName: item.unit_name,
              price: item.goods_price,
              sell_price: item.goods_price,
              brandId: Number(item.brand_id),
              brandName: item.brand_name,
              goodsDate: '',
              remark: item.info,
            }
            this.list.splice(this.list.length - 1, 0, a)
          })
          this.$message.success('添加成功')
        }
      },
      selectGoods(item, row, idx) {
        if (item.goods_id > 0) {
          console.log(item, row, '1')
          let data = {
            arr_unit: item.arr_unit,
            goodsId: item.goods_id,
            goodsPrice: item.goods_price,
            goodsName: item.goods_name,
            goodsSpec: item.specs,
            goodsNum: 0,
            unitID: item.unit_id,
            unitName: item.unit_name,
            price: item.goods_price,
            sell_price: item.goods_price,
            brandId: Number(item.brand_id),
            brandName: item.brand_name,
            goodsDate: '',
            remark: item.info,
          }
          // Object.assign(row, event)
          Object.assign(row, data)
          console.log('idx', idx)
          if (this.list.length == idx + 1) {
            console.log('+++++')
            this.list.push(this.obj())
          }
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.goodsPrice = price
        row.unit_name = name
      },
      handleCopy(index, row) {
        let r = JSON.parse(JSON.stringify(row))
        this.list.splice(index, 0, r)
      },
      handleDelete(index, row) {
        if (this.list.length == 1) {
          this.list = [this.obj()]
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1200px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "200px" },
              attrs: {
                clearable: "",
                disabled: _vm.type == 2,
                placeholder: "仓库",
              },
              model: {
                value: _vm.depot_id,
                callback: function ($$v) {
                  _vm.depot_id = $$v
                },
                expression: "depot_id",
              },
            },
            _vm._l(_vm.depotList, function (i, idx) {
              return _c("el-option", {
                key: idx,
                attrs: { value: i.id, label: i.depot_name },
              })
            }),
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { "margin-top": "10px" },
              attrs: { stripe: "", data: _vm.list },
            },
            [
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.label == "商品名称" ? "240" : "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            item.label == "品牌"
                              ? _c("div", [
                                  _vm._v(" " + _vm._s(row.brandName) + " "),
                                ])
                              : item.label == "商品名称"
                              ? _c(
                                  "div",
                                  [
                                    _vm.type != 2
                                      ? _c("goods-search", {
                                          attrs: { "f-key": row.goodsName },
                                          on: {
                                            "add-rows": _vm.addRows,
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        })
                                      : _c("div", [
                                          _vm._v(_vm._s(row.goodsName)),
                                        ]),
                                  ],
                                  1
                                )
                              : item.label == "规格"
                              ? _c("div", [
                                  _vm._v(" " + _vm._s(row.goodsSpec) + " "),
                                ])
                              : item.label == "单位"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "选择单位",
                                          disabled: _vm.type == 2,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.unitChange($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.unitID,
                                          callback: function ($$v) {
                                            _vm.$set(row, "unitID", $$v)
                                          },
                                          expression: "row.unitID",
                                        },
                                      },
                                      _vm._l(row.arr_unit, function (item_) {
                                        return _c("el-option", {
                                          key: item_.id,
                                          attrs: {
                                            label: item_.unit_name,
                                            value: item_.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : item.label == "单价"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: _vm.type == 2 },
                                      model: {
                                        value: row.goodsPrice,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goodsPrice", $$v)
                                        },
                                        expression: "row.goodsPrice",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "申请数量"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "数量",
                                        type: "number",
                                        min: 0,
                                        disabled: _vm.type != 1,
                                      },
                                      model: {
                                        value: row.apply_read_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "apply_read_num", $$v)
                                        },
                                        expression: "row.apply_read_num",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "审核数量" && _vm.type != 1
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "审核数量",
                                        type: "number",
                                        disabled: _vm.type != 3,
                                        min: 0,
                                      },
                                      model: {
                                        value: row.read_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "read_num", $$v)
                                        },
                                        expression: "row.read_num",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "金额"
                              ? _c("div", [
                                  _vm.type != 2
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                Number(row.goodsPrice) *
                                                  Number(row.apply_read_num) ||
                                                0
                                              ).toFixed(2) || 0
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v(_vm._s(row.money))]),
                                ])
                              : item.label == "生产日期"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        format: "yyyy 年 MM 月 dd 日",
                                        "value-format": "yyyy-MM-dd",
                                        disabled: _vm.type == 2,
                                      },
                                      model: {
                                        value: row.goodsDate,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goodsDate", $$v)
                                        },
                                        expression: "row.goodsDate",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "备注"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "备注",
                                        disabled: _vm.type == 2,
                                      },
                                      model: {
                                        value: row.remark,
                                        callback: function ($$v) {
                                          _vm.$set(row, "remark", $$v)
                                        },
                                        expression: "row.remark",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.type != 2
                ? _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "130px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleCopy($index, row)
                                    },
                                  },
                                },
                                [_vm._v(" 复制 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleDelete($index, row)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      175537479
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
          _c("el-input", {
            staticStyle: { width: "200px", "margin-top": "10px" },
            attrs: { disabled: _vm.type == 2, placeholder: "备注" },
            model: {
              value: _vm.remark,
              callback: function ($$v) {
                _vm.remark = $$v
              },
              expression: "remark",
            },
          }),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px", "text-align": "right" } },
            [
              _vm.type == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e(),
              _vm.type == 3
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v(" 审核 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }